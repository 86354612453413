import { useQuery } from '@tanstack/react-query';
import cx from 'classnames';
import { useAtom } from 'jotai';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { ReactNode, useMemo, useRef, useState } from 'react';

import useOnClickOutside from '../../../hooks/click-outside';
import Chevrelon from '../../ui/icons/cheverlon';
import { selectLanguageOpen } from '../../../state/select-language';
import { loadLanguages } from '../language-selector';
import { useAppContext } from '../../providers/app-context';

export const LanguageSelector = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const { locale = 'en', locales } = useRouter();
  const { isBparts } = useAppContext();
  const [, setCurrentSelect] = useState<boolean>(false);
  useOnClickOutside(ref, () => setCurrentSelect(false));
  const [, setOpen] = useAtom(selectLanguageOpen);

  const { data: languages } = useQuery(
    ['languages-list', locale, locales, isBparts],
    loadLanguages
  );

  const language = useMemo(() => {
    if (languages) {
      return languages.find((l) => l.selected);
    }

    return undefined;
  }, [languages]);

  const label = useMemo(() => {
    if (language)
      return (
        <>
          <span className={cx('block', 'py-2')}>
            {language.language || language.label}
          </span>
        </>
      );
  }, [language]);

  return (
    <div
      ref={ref}
      className="relative max-lg:w-full flex flex-col w-full lg:pl-4 justify-start"
    >
      <span className="font-extrabold text-sm text-left text-white pb-1">
        {t('common:userSettings.language')}
      </span>
      <button
        onClick={() => setOpen(true)}
        className="border-b h-[48px] w-full hover:bg-white hover:text-neutral-80"
      >
        <div
          className={cx(
            'flex justify-between items-center gap-5',
            'px-4 font-bold',
            'h-[48px]'
          )}
        >
          <div className="flex flex-row justify-between gap-[50px] max-lg:w-full max-lg:gap-[35px]">
            {label}
          </div>
          <Chevrelon
            className={cx(
              'w-3',
              'transition-transform duration-300 ease-in-out -rotate-90'
            )}
          />
        </div>
      </button>
    </div>
  );
};
