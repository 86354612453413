import { useRouter } from 'next/router';

import { loadShippingLocations } from '../components/modules/shipping-selector';
import { useAppContext } from '../components/providers/app-context';

type redirectToNewShippingLocationParams = {
  locations: Awaited<ReturnType<typeof loadShippingLocations>>;
  newShippingLocation: string | number;
  push: ReturnType<typeof useRouter>['push'];
  isBparts: boolean;
};

export const redirectToNewShippingLocation = async ({
  locations,
  newShippingLocation,
  push,
  isBparts,
}: redirectToNewShippingLocationParams) => {
  const isUK =
    locations.find((location) => {
      return location.value.toString() == newShippingLocation;
    })?.country_code === 'GB';

  if (isUK) {
    if (window.location.hostname.includes('.co.uk')) {
      // se novo shipping é uk e donmínio é .co.uk => reload
      window.location.replace(window.location.pathname);
    } else {
      // se novo shipping é uk e domínio é .com => push
      push(
        isBparts
          ? process.env.NEXT_PUBLIC_HOST?.includes('dev')
            ? `https://dev.b-parts.co.uk?shipping_location=${newShippingLocation}`
            : `https://www.b-parts.co.uk?shipping_location=${newShippingLocation}`
          : `${process.env.NEXT_PUBLIC_HOST}?shipping_location=${newShippingLocation}`
      );
    }
  } else {
    if (window.location.hostname.includes('.co.uk')) {
      // se novo shipping não é uk e domínio é .co.uk => redirect
      push(
        `${process.env.NEXT_PUBLIC_HOST}?shipping_location=${newShippingLocation}`
      );
    } else {
      // se novo shipping não é uk e domínio é .com => reload
      window.location.replace(window.location.pathname);
    }
  }
};
