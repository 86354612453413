import { useQuery } from '@tanstack/react-query';
import cx from 'classnames';
import { useAtom } from 'jotai';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { ReactNode, useMemo, useRef, useState } from 'react';

import useOnClickOutside from '../../../hooks/click-outside';
import { shippingLocationOpen } from '../../../state/shipping-location';
import Chevrelon from '../../ui/icons/cheverlon';
import BPImage from '../../ui/image';
import { loadShippingLocations } from '../shipping-selector';

export const WebsiteOptionDropdown = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const { locale = 'en' } = useRouter();
  const [currentSelect, setCurrentSelect] = useState<boolean>(false);
  useOnClickOutside(ref, () => setCurrentSelect(false));
  const [, setOpen] = useAtom(shippingLocationOpen);

  const { data: shippings } = useQuery(
    ['user-dependent', 'shipping-locations-list', locale],
    loadShippingLocations
  );

  const shipping = useMemo(() => {
    if (shippings) {
      const currentShipping = shippings.find((s) => s.selected);
      return currentShipping;
    }

    return undefined;
  }, [shippings]);

  const label = useMemo(() => {
    if (shipping && shipping.icon)
      return (
        <>
          <BPImage
            src={shipping.icon}
            width={16}
            height={16}
            alt={shipping.label}
            className="shadow-sm"
          />
          <span className={cx('block', 'py-2')}>{shipping.label}</span>
        </>
      );
  }, [shipping]);

  return (
    <div
      ref={ref}
      className="relative max-lg:w-full flex flex-col w-full lg:pl-4 justify-start"
    >
      <span className="font-extrabold text-sm text-left text-white pb-1">
        {t('common:userSettings.shipping')}
      </span>
      <button
        onClick={() => setOpen(true)}
        className="border-b h-[48px] max-lg:w-full hover:bg-white hover:text-neutral-80"
      >
        <div
          className={cx(
            'flex lg:justify-between items-center lg:gap-5',
            'px-4 font-bold',
            'h-[48px]'
          )}
        >
          <div className="flex flex-row gap-4 max-lg:w-full">{label}</div>
          <Chevrelon
            className={cx(
              'w-3',
              'transition-transform duration-300 ease-in-out -rotate-90',
              {
                'rotate-180': currentSelect,
              }
            )}
          />
        </div>
      </button>
    </div>
  );
};
