import cx from 'classnames';
import useTranslation from 'next-translate/useTranslation';

import BPImage from '../../ui/image';

import classes from './index.module.css';

const delivers = [
  {
    width: 71,
    height: 24,
    alt: 'footer:shipping_partners.alts.fedex',
    src: `${process.env.PUBLIC_PREFIX}/svg/icons/fedex.svg`,
  },
  {
    width: 71,
    height: 24,
    alt: 'footer:shipping_partners.alts.dhl',
    src: `${process.env.PUBLIC_PREFIX}/svg/icons/dhl.svg`,
  },
  {
    width: 71,
    height: 24,
    alt: 'footer:shipping_partners.alts.dpd',
    src: `${process.env.PUBLIC_PREFIX}/svg/icons/dpd.svg`,
  },
  {
    width: 71,
    height: 24,
    alt: 'footer:shipping_partners.alts.dachser',
    src: `${process.env.PUBLIC_PREFIX}/svg/icons/dachser.svg`,
  },
];

export const Delivers = ({ isCheckout = false }) => {
  const { t } = useTranslation();

  return (
    <section
      className={cx(classes.shipping, 'bg-neutral-80')}
      aria-labelledby="shipping-partners"
    >
      <span
        id="shipping-partners"
        className={cx('inline-block', 'font-bold', 'mb-4')}
      >
        {t('footer:shipping_partners.title')}
      </span>
      <ul className="flex items-center">
        {delivers.map((item) => (
          <li className="mr-4 flex last-of-type:mr-0" key={item.alt}>
            <BPImage
              className="h-6"
              src={item.src}
              alt={t(item.alt)}
              height={item.height}
              width={item.width}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};
